import ProgramProjection from 'domains/enums/program/ProgramProjection'
import Rule from 'domains/rule/Rule'
import * as LoadProgramUseCase from 'usecase/program/LoadProgramUseCase'
import * as ProgramRulesToRules from 'converters/program/ProgramRulesToRules'
import * as ItemService from '@/services/item/ItemService'
import * as FeatureToggle from '@/utils/featureToggle'
import { isToggleEnabled } from '@/hooks'
import SkuForm from '@/domains/sku/SkuForm'
import { isToggleEarningRulesByVendorEnabled } from '@/utils/featureToggle/featureToggleHelper'
import agingGroupValidate from '@/utils/agingGroupValidate'
import VariantsType from '@/domains/Item/variantsType'

export type SkuFound = {
	sku: SkuForm
	parentRule?: Rule
}

const getParentRule = async (sku: string): Promise<Rule | undefined> => {
	if (isToggleEarningRulesByVendorEnabled()) {
		return undefined
	}
	const multiVendorToggle = isToggleEnabled(FeatureToggle.DEV_MULTI_VENDOR_EARNING_RULES)
	const program = await LoadProgramUseCase.execute(ProgramProjection.Rules)
	const programRuleFound = program?.rules.find((rule) =>
		multiVendorToggle ? rule.items?.find(({ vendorItemId }) => vendorItemId === sku) : rule.skus.includes(sku),
	)
	return programRuleFound ? ProgramRulesToRules.convert(programRuleFound, multiVendorToggle) : undefined
}

async function execute(sku: string, variantsType?: VariantsType): Promise<SkuFound | undefined> {
	const multiVendorToggle = isToggleEnabled(FeatureToggle.DEV_MULTI_VENDOR_EARNING_RULES)

	const response = multiVendorToggle
		? await ItemService.loadAllItems({ vendorItemIds: [sku], variantsType }).catch(() => undefined)
		: await ItemService.loadAll({ vendorItemIds: [sku], variantsType }).catch(() => undefined)

	if (response?.items?.length) {
		const skuObject = response.items[0]
		const agingValidate = agingGroupValidate(skuObject.agingGroup)
		if (!agingValidate) return Promise.resolve(undefined)

		const parentRule = await getParentRule(skuObject.sku)

		return {
			sku: {
				id: multiVendorToggle ? skuObject.vendorItemId ?? '' : skuObject.sku,
				sku: skuObject.sku,
				name: skuObject.itemName,
				itemId: skuObject.id,
				image: skuObject.itemImage,
			},
			parentRule,
		}
	}
	return Promise.resolve(undefined)
}

async function executeList(skus: Array<string>, variantsType?: VariantsType): Promise<Array<SkuFound | undefined>> {
	const multiVendorToggle = isToggleEnabled(FeatureToggle.DEV_MULTI_VENDOR_EARNING_RULES)

	const response = multiVendorToggle
		? await ItemService.loadAllItems({ vendorItemIds: skus, variantsType }).catch(() => undefined)
		: await ItemService.loadAll({ vendorItemIds: skus, variantsType }).catch(() => undefined)

	if (response?.items.length) {
		const skuObject = response.items

		const skuFounds = await Promise.all(
			skuObject.map(async (item): Promise<SkuFound | undefined> => {
				const agingValidate = agingGroupValidate(item.agingGroup)
				if (!agingValidate) return undefined

				const parentRule = await getParentRule(item.sku)

				return {
					sku: {
						id: multiVendorToggle ? item.vendorItemId ?? '' : item.sku,
						name: item.itemName,
						itemId: item.id,
						image: item.itemImage,
						sku: item.sku,
					},
					parentRule,
				}
			}),
		)
		return skuFounds
	}
	return []
}

export { execute, executeList }
