import { PagedResponse } from '@/domains'
import ItemResponse from '@/domains/Item/ItemResponse'
import { SettingsLevel, SettingsType } from '@/domains/enums/settings'
import { SettingBodyUpdateConfiguration, SettingDefaultConfiguration } from '@/domains/settings'
import { Tier } from '@/domains/tier'
import axios from '@/utils/axiosInstance'
import { castListToStringParam } from '@/utils/axiosParams'
import { isToggleSettingsConfigByZoneEnabled } from '@/utils/featureToggle/featureToggleHelper'
import { useLogService as logService } from 'admin-portal-shared-services'
import { AxiosResponse } from 'axios'

const BASE_URL = `/rewards-service/v1/settings`
const BASE_ADMIN_URL = `/rewards-admin-service/v1`
const log = logService()

export interface LoadParams {
	page?: number
	pageSize?: number
	tiers: Array<string>
	settingIds: string
	levels: SettingsLevel
	types: SettingsType
}
export interface LoadEarnRulesParams {
	vendorId?: string
	tier: string
}

export interface CreateNewRuleParams {
	level: string
	ruleId: string
	settingsId: string
	tier: string
	rule: {
		amountSpent: number
		points: number
		categoryId: string
		description?: string
	}
}

export interface ChangeConfigParams {
	settingsId: string
	level: SettingsLevel
	tiers: Array<string>
}

export interface DeleteSkuParams {
	ruleId: string
	settingId: string
	tier: string | null
	categoryId: string | null
	itemId: string
	level: string
}

export interface AddItemInRuleParams {
	level: string
	ruleId: string
	settingId: string
	tier: string
	categoryId: string
	itemId: string
	item: {
		vendorId: string
		vendorItemId: string
		sku: string
	}
}

export interface AddItemsInBulkParams {
	level: string
	ruleId: string
	settingId: string
	tier: string
	categoryId: string
	fileName: string
	searchIds: string[]
}

export interface LoadDefaultConfigParams {
	settingIds: string
	levels: SettingsLevel
	tiers: Array<string>
	types: SettingsType
}

export interface LoadAllItemsByRuleParams {
	ruleId: string
	includeDeleted?: boolean
	includeDisabled?: boolean
	includeAlcoholic?: boolean
	includeNarcotic?: boolean
	tier: string
	vendorId: string
	page?: number
	pageSize?: number
}

export async function load(): Promise<Array<Tier>> {
	const { data } = await axios.get(`${BASE_ADMIN_URL}/settings/tiers`)
	return data.tiers
}

export const loadEarnRules = async <T>(params: LoadEarnRulesParams): Promise<Array<T>> => {
	const { vendorId, tier } = params
	const response = await axios.get<Array<T>>(`${BASE_ADMIN_URL}/rules`, {
		params: {
			tier,
			...(vendorId && { vendorId }),
		},
	})
	return response.data
}

export const loadSettings = async <T>(params: LoadParams): Promise<Array<T>> => {
	const { page, pageSize, tiers, settingIds, types, levels } = params
	const response = await axios.get<PagedResponse<T>>(`${BASE_URL}`, {
		params: {
			page,
			pageSize,
			tiers: castListToStringParam(tiers),
			settingIds,
			types,
			levels,
		},
	})
	return response.data.content
}

export async function createNewRule(params: CreateNewRuleParams): Promise<AxiosResponse> {
	const { settingsId, level, ruleId, tier, rule } = params
	const endPoint = `${BASE_ADMIN_URL}/rules/${ruleId}/${settingsId}/${level}/${tier}`
	return axios.put<void>(endPoint, rule)
}

export async function updateConfiguration(
	params: ChangeConfigParams,
	body: SettingBodyUpdateConfiguration,
): Promise<AxiosResponse> {
	const { settingsId, level, tiers } = params
	const tier = castListToStringParam(tiers)
	const endPoint = `${BASE_URL}/${settingsId}/default-configuration/${level}/${tier}`
	return axios.patch<SettingDefaultConfiguration>(endPoint, body)
}

export async function deleteSkuFromRule(params: DeleteSkuParams): Promise<void> {
	const { ruleId, settingId, tier, categoryId, itemId, level } = params
	const endPointDelete = `${BASE_ADMIN_URL}/rules/${ruleId}/${settingId}/${level}/${tier}/categories/${categoryId}/items/${itemId}`
	await axios
		.delete<void>(endPointDelete)
		.then((response) => response.data)
		.catch((error) => {
			log.error(`Error endpoint: ${endPointDelete}`, error)
			throw error
		})
}

export async function addItemInRule(params: AddItemInRuleParams): Promise<AxiosResponse> {
	const { ruleId, settingId, level, tier, categoryId, itemId, item } = params
	const endPoint = `${BASE_ADMIN_URL}/rules/${ruleId}/${settingId}/${level}/${tier}/categories/${categoryId}/items/${itemId}`
	return axios.put<void>(endPoint, item)
}

export async function addItemsInRuleInBulk(params: AddItemsInBulkParams): Promise<AxiosResponse> {
	const { ruleId, settingId, level, tier, categoryId, fileName, searchIds } = params
	const body = {
		fileName,
		searchIds,
	}
	const endPoint = `${BASE_ADMIN_URL}/rules/${ruleId}/${settingId}/${level}/${tier}/categories/${categoryId}/items`
	return axios.post<void>(endPoint, body)
}

export async function loadAllItemsByRule(params: LoadAllItemsByRuleParams): Promise<ItemResponse> {
	const { ruleId, tier, vendorId, includeAlcoholic, includeDeleted, includeDisabled, includeNarcotic, page, pageSize } =
		params

	const paramsRequest = {
		includeDeleted,
		includeDisabled,
		includeAlcoholic,
		includeNarcotic,
		tier,
		page,
		pageSize: pageSize ?? 999,
	}

	// eslint-disable-next-line no-unused-expressions
	!isToggleSettingsConfigByZoneEnabled() && Object.assign(paramsRequest, { vendorId })

	const response = await axios.get<ItemResponse>(`${BASE_ADMIN_URL}/rules/${ruleId}/items`, {
		params: paramsRequest,
	})
	return response.data
}
