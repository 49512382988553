import React from 'react'

const CreateEarningRuleFlowV2: React.FC = () => {
	return (
		<div>
			<h1> Create Earning rule flow</h1>
		</div>
	)
}

export default CreateEarningRuleFlowV2
