/**
 * Accepted countries is used to control the roles between shared-services and our application
 */

export type AcceptedCountry =
	| 'DO'
	| 'BR'
	| 'ZA'
	| 'MX'
	| 'CO'
	| 'AR'
	| 'EC'
	| 'PE'
	| 'PY'
	| 'SV'
	| 'PA'
	| 'UY'
	| 'HN'
	| 'GB'
	| 'GH'
	| 'TZ'
	| 'UG'
	| 'CA'
	| 'BO'
	| 'ES'
	| 'BE'
	| 'NL'
	| 'PH'
	| 'AQ'
	| 'ID'
	| 'CL'
	| 'TH'
	| 'KR'

const acceptedCountries: AcceptedCountry[] = [
	'DO',
	'BR',
	'ZA',
	'MX',
	'CO',
	'AR',
	'EC',
	'PE',
	'PY',
	'SV',
	'PA',
	'UY',
	'HN',
	'GB',
	'GH',
	'TZ',
	'UG',
	'CA',
	'BO',
	'ES',
	'BE',
	'NL',
	'PH',
	'AQ',
	'ID',
	'CL',
	'TH',
	'KR'
]

export default acceptedCountries
