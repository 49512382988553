import React from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from 'effector-react'
import { Alert } from '@hexa-ui/components'
import { PermissionLevel } from '@/domains/enums'
import { useAuthorization } from '@/hooks'
import { useHideTierInfo } from '@/hooks/tier/useHideTierInfo'
import { GlobalStore } from '@/stores'
import { useGetFeatureTogglesValuesToSelectedInfo } from '@/routes/rules/hooks/useGetFeatureTogglesValuesToSelectedInfo/useGetFeatureTogglesValuesToSelectedInfo'

const SelectedInfo = () => {
	const { t } = useTranslation()
	const { isFeatureAuthorized } = useAuthorization()
	const { selectedProgram, tiersMap, selectedTierKey } = useStore(GlobalStore)
	const { featureToggleProgram, featureToggleTiers, isSelectTiersEnabled } = useGetFeatureTogglesValuesToSelectedInfo()
	const shouldHideTierInfo = useHideTierInfo()

	const isConfigurationAvailable = isFeatureAuthorized(featureToggleProgram! || featureToggleTiers!, {
		feature: 'ProgramConfiguration',
		permissionLevel: PermissionLevel.Delete,
	})

	if (!isConfigurationAvailable || shouldHideTierInfo) return null

	if (isSelectTiersEnabled! && selectedTierKey) {
		return (
			<Alert
				css={{ width: '100%' }}
				message={`${t('common:SELECTED.LABEL_TIER')} ${tiersMap[selectedTierKey].label}`}
			/>
		)
	}

	return (
		<Alert
			css={{ width: '100%' }}
			message={`${t('common:SELECTED.LABEL_PROGRAM')} ${selectedProgram?.name ?? 'none (first available will be used)'}`}
		/>
	)
}

export default SelectedInfo
