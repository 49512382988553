import React, { useEffect } from 'react'
import { useStoreMap } from 'effector-react'
import { useTranslation } from 'react-i18next'
import { RedeemableItem } from '@/domains/redeemable/RedeemableItem'
import useScopeAuthorization from '@/hooks/useScopeAuthorization/useScopeAuthorization'
import { $redeemableItemsStore, CreateRedeemableItemStoreEvents, PricePerPointConfigurationStoreEvents } from '@/stores'
import { REDEEMABLE_DEFAULT_CPP, REDEEMABLE_SKU_CPP, TABLE_PAGE_SIZE_OPTIONS } from '@/utils/constants'
import { isToggleRedeemableItemsSortByDisabled } from '@/utils/featureToggle/featureToggleHelper'
import { EmptyState, Grid, PaginationProps, Table } from '@hexa-ui/components'
import { formatDate } from '@/utils/date'
import { ViewState } from '@/domains/redeemable/ViewState'
import { UseRedeemableItemsListResult } from '../../hooks/useRedeemableItemsList'
import { defaultSortby, defaultSortingOrder } from '../../../RedeemableItemsList/useRedeemableItemsList'
import { RedeemableStatus } from './components/RedeemableStatus'
import { RedeemableItemProduct } from './components/RedeemableItemProduct'
import { RedeemableItemPricePerPoint } from './components/RedeemableItemPricePerPoint'
import { RedeemableItemActions } from './components/RedeemableItemActions'
import RedeemableItemsTableStyle from './RedeemableItemsTableStyles'

type RedeemableItemsTableProps = Omit<UseRedeemableItemsListResult, 'isFirstLoad' | 'isLoading' | 'hasError'> & {
	viewState: ViewState
}

export const RedeemableItemsTable = ({
	viewState,
	data,
	onSearchChanged,
	onSortChanged,
	onPaginationChanged,
}: RedeemableItemsTableProps) => {
	const { t } = useTranslation()
	const isSortByDisabled = isToggleRedeemableItemsSortByDisabled()
	const hasSKUCPPPermission = useScopeAuthorization([REDEEMABLE_SKU_CPP])
	const hasDefaultCPPPermission = useScopeAuthorization([REDEEMABLE_DEFAULT_CPP])

	const isLoading = viewState === ViewState.LOADING
	const hasError = viewState === ViewState.ERROR

	const css = RedeemableItemsTableStyle()

	const pagination = useStoreMap({
		store: $redeemableItemsStore,
		keys: [viewState, onPaginationChanged],
		fn: ({ pagination: storePagination }): Partial<PaginationProps> => {
			return {
				// The initial page on the View (<Table> component) is 1 and on the ms it's 0 based.
				current: 1 + storePagination.page,
				pageSize: storePagination.pageSize,
				total: storePagination.totalElements,
				pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS.REDEEMABLE_ITEMS,
				showQuantityIndicator: !isLoading,
				showPageSizeSelector: !isLoading,
				onChange: onPaginationChanged,
				pageSizeOptionsIntl: (option) => {
					return t('common:TABLE_PAGINATION_SIZE_OPTIONS', {
						option,
					})
				},
			}
		},
	})

	const getActionColumn = (hasSKUPermission: boolean, hasDefaultPermission: boolean) => {
		if (!hasSKUPermission || !hasDefaultPermission) return null

		return {
			Header: t('common:ACTIONS'),
			accessor: 'actions',
			label: t('VIEW'),
			disableSortBy: true,
			style: {
				width: '120px',
			},
			customRender: CustomRenderForActionsColumn,
		}
	}

	const columns = [
		{
			Header: t('redeemable:ITEMS_LIST_TABLE.PRODUCT'),
			accessor: 'itemName',
			disableSortBy: true,
			style: {
				width: 'auto',
				minWidth: '320px',
			},
			customRender: CustomRenderForProductColumn,
		},
		{
			Header: t('redeemable:ITEMS_LIST_TABLE.SKU'),
			accessor: 'vendorItemId',
			disableSortBy: isSortByDisabled,
			style: {
				width: '160px',
			},
		},
		{
			Header: t('redeemable:ITEMS_LIST_TABLE.COST_PER_POINT'),
			accessor: 'pricePerPoint',
			disableSortBy: isSortByDisabled,
			style: {
				width: '195px',
			},
			customRender: CustomRenderForPricePerPointColumn,
		},
		{
			Header: t('redeemable:ITEMS_LIST_TABLE.LAST_UPDATE'),
			accessor: 'lastModified',
			disableSortBy: isSortByDisabled,
			style: {
				width: '200px',
			},
			customRender: CustomRenderForLastModifiedColumn,
		},
		{
			Header: t('redeemable:ITEMS_LIST_TABLE.STATUS'),
			accessor: 'status',
			label: t('VIEW'),
			disableSortBy: isSortByDisabled,
			style: {
				width: '160px',
			},
			customRender: CustomRenderForStatusColumn,
		},
		getActionColumn(hasSKUCPPPermission, hasDefaultCPPPermission),
	].filter(Boolean)

	useEffect(() => {
		PricePerPointConfigurationStoreEvents.setIsOpen(false)
		CreateRedeemableItemStoreEvents.reset()

		return () => {
			PricePerPointConfigurationStoreEvents.setIsOpen(false)
			CreateRedeemableItemStoreEvents.reset()
		}
	}, [])

	return (
		<Grid.Container className={css.mainTable}>
			<Table
				search
				searchPlaceholder={t('redeemable:SEARCH_PLACEHOLDER')}
				onSearchChange={onSearchChanged}
				onSort={onSortChanged}
				initialSortBy={[
					{
						id: defaultSortby,
						desc: defaultSortingOrder === 'DESC',
					},
				]}
				pagination={pagination}
				loading={isLoading}
				emptyMessage={<EmptyMessage hasError={hasError} />}
				data={data}
				columns={columns}
			/>
		</Grid.Container>
	)
}

function EmptyMessage({ hasError }: Readonly<{ hasError: boolean }>) {
	const { t } = useTranslation()
	const css = RedeemableItemsTableStyle()

	const description = hasError
		? t('common:ERROR_MESSAGE:GENERIC_ERROR')
		: t('redeemable:CREATE.ITEM_NOT_FOUND.DESCRIPTION')

	return (
		<Grid.Item className={css.emptyMessage}>
			<EmptyState.SectionLevel description={description} />
		</Grid.Item>
	)
}

// CustomRender Table Columns

function CustomRenderForProductColumn(_: string, itemData: RedeemableItem) {
	return <RedeemableItemProduct {...itemData} />
}

function CustomRenderForPricePerPointColumn(_: string, itemData: RedeemableItem) {
	return <RedeemableItemPricePerPoint item={itemData} />
}

function CustomRenderForLastModifiedColumn(value: Date) {
	const { t } = useTranslation()
	return !value ? '—' : formatDate(value, t('DATE_FORMAT_TABLE'))
}

function CustomRenderForActionsColumn(_: string, itemData: RedeemableItem) {
	return <RedeemableItemActions item={itemData} />
}

function CustomRenderForStatusColumn(_: string, itemData: RedeemableItem) {
	return <RedeemableStatus {...itemData} />
}
