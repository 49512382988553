import React from 'react'
import { Button, Dialog, Heading, IconButton, Paragraph, Tooltip } from '@hexa-ui/components'
import { Trash2 } from '@hexa-ui/icons'
import { useTranslation } from 'react-i18next'
import { RedeemableItem } from '@/domains/redeemable/RedeemableItem'
import { useRedeemableItemActions } from '@/routes/redeemableItems/components/RedeemableItemsListV2/hooks/useRedeemableItemActions'
import useStyles from './DeleteRedeemableItensStyle'

interface DeleteRedeemableItemsProps {
	item: RedeemableItem
}
const DeleteRedeemableItems: React.FC<DeleteRedeemableItemsProps> = ({ item }) => {
	const { t } = useTranslation()
	const icon = () => <Trash2 size="medium" fr={undefined} />
	const { buildRedeemableAction } = useRedeemableItemActions()
	const action = buildRedeemableAction(item)
	return (
		<Dialog.Root
			actions={<Actions deleteAction={action.onDelete} />}
			title={<Heading size="H3">{t('redeemable:DELETE.TITLE')}</Heading>}
			trigger={<IconButton icon={icon} variant="tertiary" size="small" data-testid="remove-redeemable-item-icon" />}
		>
			<Paragraph>{t('redeemable:DELETE.DESCRIPTION')}</Paragraph>
		</Dialog.Root>
	)
}
interface ActionProps {
	deleteAction: () => void
}
const Actions = ({ deleteAction }: ActionProps) => {
	const css = useStyles()
	const { t } = useTranslation()
	return (
		<div className={css.wrapperButtons} data-testid="delete-redeemable-items">
			<Dialog.Close>
				<Button
					size="large"
					variant="destructive"
					onClick={() => deleteAction()}
					data-testid="delete-redeemable-items-button"
				>
					{t('redeemable:DELETE.BUTTON.DELETE')}
				</Button>
			</Dialog.Close>
			<Dialog.Close>
				<Button size="large" variant="secondary">
					{t('redeemable:DELETE.BUTTON.CANCEL')}
				</Button>
			</Dialog.Close>
		</div>
	)
}
export default DeleteRedeemableItems
