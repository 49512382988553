import { styled } from '@bees/vision-tokens'

export const ButtonsContainer = styled('div', {
	display: 'flex',
	marginTop: '10px',
	alignItems: 'center',
	justifyContent: 'space-between',
	position: 'relative',
	height: '48px',
})

export const Wrapper = styled('div', {
	display: 'flex',
	'& > button:last-child': {
		marginLeft: '16px',
	},
	position: 'absolute',
	right: '0',
})
