import languages from '@/configs/countries.json'
import { PaginationProps } from '@hexa-ui/components'
import { enUS, ptBR, es, fr, de } from 'date-fns/locale'

/**
 * Base route for this micro frontend
 */
export const BASE_ROUTE = '/membership'

/**
 * Base URL services
 */
export const SERVICE_URL = {
	REWARDS: '/rewards-service',
	REWARDS_ADMIN: '/rewards-admin-service',
}

/**
 * API endpoints
 */
export const API_URL = {
	REDEEMABLE_ITEMS: `${SERVICE_URL.REWARDS_ADMIN}/v1/redeemables`,
	GET_REFUND: `${SERVICE_URL.REWARDS_ADMIN}/v1/transactions/refund/:orderId`,
	POST_REFUND: `${SERVICE_URL.REWARDS_ADMIN}/v1/transactions/:accountId/refund`,
	GET_SUPPORTED_LANGUAGE: `${SERVICE_URL.REWARDS_ADMIN}/v1/configurations/supportedlanguages`,
}

/**
 * Base route for challenge
 */
export const CHALLENGE_BASE_ROUTE = `${BASE_ROUTE}/challenge`

/**
 * Regex for challenge form steps
 */
export const CHALLENGE_STEPS_REGEX = `${CHALLENGE_BASE_ROUTE}/(setup|filter|details)`

/**
 * Max size file 1mb (1000000 bytes)
 */
export const MAX_SIZE_FILE_1MB = 1000000

/**
 * Max size file 5mb (5000000 bytes)
 */
export const MAX_SIZE_FILE_5MB = 5000000

/**
 * Supported file types for upload
 */
export const SUPPORTED_FILE_TYPE_CSV = ['text/csv']

/**
 * Supported file types for Image
 */
export const SUPPORTED_FILE_TYPE_IMAGE = ['image/jpg', 'image/jpeg', 'image/png']

/**
 * Default image to be used as placeholder when image loading fails
 */
export const DEFAULT_IMAGE_FALLBACK_URL = 'https://cdn-b2b-abi.global.ssl.fastly.net/admin/images/product-level.svg'

/**
 * Default date locale code used on date formatting
 */
export const DEFAULT_DATE_LOCALE_CODE = 'en'

export const DEFAULT_LANGUAGE = 'en-US'

/**
 * Supported file extensions for upload
 */
export const SUPPORTED_FILE_EXTENSION_CSV = ['csv']

/**
 * HTTP Status Code 423 (Locked)
 */
export const HTTP_LOCKED = 423

/**
 * String value used as a key to store the selected zone by the user in the browser local storage
 */
export const MULTI_ZONE_LOCAL_STORAGE_KEY = 'membership-selected-zone'

export const MULTI_VENDOR_ATTRIBUTES_STORAGE_KEY = 'membership-multivendor-attributes'

/**
 * Maximum integer value size
 */
export const MAX_INTEGER_SIZE = 10

/**
 * Maximum decimal value size
 */
export const MAX_DECIMAL_SIZE = 6

/**
 * Delay time to wait before performing any change while user is typing (in ms)
 */
export const TYPING_CHANGE_DEBOUNCE_DELAY = 600

/**
 * Delay time to wait before performing transition between modals (in ms)
 */
export const MODAL_CHANGE_DELAY = 300

interface CountryConfig {
	locale: string
	currency: string
}
/**
 * Available countries configuration (locale, currency...)
 */
export const countryConfig: Record<string, CountryConfig> = languages

/**
 * Default tier to be used to fetch PPP settings
 */
export const DEFAULT_TIER_FOR_PPP = 'CLUB_B'

/**
 * PageSize options
 */
export const TABLE_PAGE_SIZE_OPTIONS = {
	DEFAULT: [10, 25, 50],
	REDEEMABLE_ITEMS: [7, 25, 50],
}

export const TABLE_PAGINATION_CONFIG: Partial<PaginationProps> = {
	current: 1,
	pageSize: 10,
	showPageSizeSelector: true,
	pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS.DEFAULT,
}

export const LOCALES: { [key: string]: Locale } = {
	en: enUS,
	pt: ptBR,
	es,
	fr,
	de,
}

/**
 * Project name to be added within Optimizely
 */
export const PROJECT_NAME = 'BEES - Rewards Admin Service'

/**
 * Redeemable CPP Permission
 */
export const REDEEMABLE_SKU_CPP = 'Membership.RedeemablesSKUCPP.Delete'
export const REDEEMABLE_DEFAULT_CPP = 'Membership.RedeemablesDefaultCPP.Delete'

/**
 * Create Rules Permission
 */
export const CREATE_EARNING_RULES = 'Membership.CreateEarningRules.Delete'
export const CREATE_EXCLUSION_LIST = 'Membership.CreateExclusionList.Delete'

/**
 * Custom countries configuration for translation and currency
 */
export const CUSTOM_COUNTRIES = {
	PY: {
		locale: 'es-PY',
		currency: 'BRL',
	},
	CL: {
		locale: 'en-US',
		currency: 'USD',
	},
	KR: {
		locale: 'en-US',
		currency: 'USD',
	},
}

export const TOGGLE_EARNING_EXCLUSION = true
